<template>
	<div class="manual-estimate-dialog">
		<div class="top-row">
			<div class="icon-wrapper" @click="goBack"><van-icon name="arrow-left" /></div>
			<span class="text">人工综合评价</span>
		</div>
		<template v-if="userInfo && userInfo.token">
			<div class="text-description" style="margin-top: 0.5rem">1.您看完候选人的简历之后，还想面试TA吗？</div>
			<div class="radio-wrapper">
				<div class="radio-item" :class="{ active: resumeResult === 1 }" @click="resumeResult = 1">是</div>
				<div class="radio-item" :class="{ active: resumeResult === 2 }" @click="resumeResult = 2">否</div>
				<div class="radio-item" :class="{ active: resumeResult === 3 }" @click="resumeResult = 3">待定</div>
			</div>
			<div class="rate-wrapper">
				<van-rate v-model="resumeScore" color="#ffd21e" :size="24" :gutter="10" void-icon="star" void-color="#eee" />
			</div>

			<van-field v-model="resumeText" rows="2" autosize type="textarea" maxlength="500" placeholder="请填写具体原因" show-word-limit />

			<template v-if="estimateType === 2">
				<div class="text-description">2.请对候选人视频面试的表现进行五星评价</div>
				<div class="radio-wrapper">
					<div class="radio-item" :class="{ active: videoResult === 1 }" @click="videoResult = 1">通过</div>
					<div class="radio-item" :class="{ active: videoResult === 2 }" @click="videoResult = 2">拒绝</div>
					<div class="radio-item" :class="{ active: videoResult === 3 }" @click="videoResult = 3">待定</div>
				</div>
				<div class="rate-wrapper">
					<van-rate v-model="videoScore" color="#ffd21e" :size="24" :gutter="10" void-icon="star" void-color="#eee" />
				</div>
				<van-field v-model="videoText" rows="2" autosize type="textarea" maxlength="500" placeholder="请填写具体评价(限制500字符)" show-word-limit />

				<div class="text-description">3.请对候选人的综合表现进行五星评价，并决定是否推荐进入下一轮面试</div>
				<div class="radio-wrapper">
					<div class="radio-item" :class="{ active: totalResult === 1 }" @click="totalResult = 1">通过</div>
					<div class="radio-item" :class="{ active: totalResult === 2 }" @click="totalResult = 2">拒绝</div>
					<div class="radio-item" :class="{ active: totalResult === 3 }" @click="totalResult = 3">待定</div>
				</div>
				<div class="rate-wrapper">
					<van-rate v-model="totalScore" color="#ffd21e" :size="24" :gutter="10" void-icon="star" void-color="#eee" />
				</div>
				<van-field v-model="totalText" rows="2" autosize type="textarea" maxlength="500" placeholder="请填写具体评价(限制500字符)" show-word-limit />
			</template>
			<div class="btn-wrapper">
				<div class="confirm-btn" @click="totalSubmit">提交</div>
			</div>
		</template>

		<div class="estimate-log-wrapper" v-if="allEstimate && allEstimate.length">
			<div class="estimate-log-item" v-for="(l, index) in allEstimate" :key="index + '-' + l.createdTime">
				<div class="estimate-content-log">
					<div class="top-title-row">
						<div class="name">{{ l.estimateUserName }}</div>
						<div class="date">{{ moment(l.createdTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
					</div>
					<div class="content-wrapper">
						<div class="content-desc">
							{{ l.estimateType | translateEstimateType }}
						</div>
						<div class="result-row" v-if="l.result && l.result > 0">
							<span class="lable">回答是:</span><span class="text">{{ l | translateResult }}</span>
						</div>
						<div class="rate-row">
							<div>评星为:</div>
							<van-rate v-model="l.estimateScore" color="#ffd21e" :size="24" :gutter="10" void-icon="star" void-color="#eee" readonly />
						</div>
						<div class="estimate-content-row" v-if="l.estimateContent">
							<span class="lable">评价为:</span>
							<span class="text">{{ l.estimateContent }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<van-empty description="暂无评价记录" v-else />

		<!-- <div class="top-row"><i class="iconfont" @click="back">&#xe61d;</i><span class="text">人工综合评价</span></div>
    <div class="estimate-row">
      <van-rate v-model="estimateScore" :size="20" void-icon="star" void-color="#eee" />
      <div class="submit-btn" @click="onSubmit">提交</div>
    </div>
    <div class="textarea-wrapper">
      <textarea v-model="estimateContent" :rows="6" autosize type="textarea" placeholder="请填写评价(限制500字符)" style="width:100%"></textarea>
    </div>
    <div class="suggerlog-wrapper">
      <div class="suggerlog" v-for="(l, index) of logList" :key="index">
        <div class="line1">
          <div class="left">
            <div class="name">{{ l.userName }}</div>
            <div class="dafen">打分为：</div>
            <van-rate v-model="l.estimateScore" :size="12" void-icon="star" :gutter="0" void-color="#eee" readonly />
          </div>
          <div class="right">{{ moment(l.createdTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
        </div>
        <div class="line2">
          <span class="pingjia">评价为:</span>
          <span class="text">{{ l.estimateContent }}</span>
        </div>
      </div>
    </div> -->
		<van-dialog v-model="showConfirmDialog" title="提交提醒" show-cancel-button @confirm="confirmDialog">
			<div class="dialog-wrapper">您的评价尚未提交，返回后将被清空，请您确认是否返回</div>
		</van-dialog>
	</div>
</template>
<script>
import moment from 'moment'
import { markShowComprehensiveEstimate, markAddComprehensiveEstimate, selectAllEstimate, addComment } from '@/api/report'
export default {
	data() {
		return {
			resumeResult: -1,
			resumeText: '',
			resumeScore: 0,

			videoResult: -1,
			videoText: '',
			videoScore: 0,

			totalResult: -1,
			totalText: '',
			totalScore: 0,

			showConfirmDialog: false,
			moment: moment,
			estimateScore: 0,
			estimateContent: '',
			logList: null,
			allEstimate: [],
		}
	},
	computed: {
		corpId() {
			return this.$store.state.corpId
		},
		userInfo() {
			return this.$store.state.userInfo
		},
		interviewId() {
			return this.$store.state.interviewId
		},
		jobseekerChannelId() {
			return this.$store.state.jobseekerChannelId
		},
		estimateType() {
			return this.$store.state.estimateType
		},
	},
	filters: {
		//1.简历 2.综合评估  3.视频人工总分
		translateEstimateType(val) {
			if (val === 1) {
				return '您看完候选人的简历之后，还想面试TA吗'
			} else if (val === 2) {
				return '请对候选人的综合表现进行五星评价，并决定是否推荐进入下一轮面试'
			} else if (val === 3) {
				return '请对候选人视频面试的表现进行五星评价'
			}
		},
		translateResult(l) {
			if (l.estimateType === 1) {
				if (l.result === 1) {
					return '是'
				} else if (l.result === 2) {
					return '否'
				} else if (l.result === 3) {
					return '待定'
				}
			} else if (l.estimateType === 2 || l.estimateType === 3) {
				if (l.result === 1) {
					return '通过'
				} else if (l.result === 2) {
					return '拒绝'
				} else if (l.result === 3) {
					return '待定'
				}
			}
		},
	},
	created() {
		this.showEstimateRecord()
		this.closeAllVideo()
		this.getAllEstimate()
	},
	methods: {
		getAllEstimate() {
			let data = {
				jobseekerChannelId: this.jobseekerChannelId,
				interviewId: this.interviewId,
			}
			selectAllEstimate(data).then(res => {
				if (res && res.code === 0) {
					this.allEstimate = res.data
				}
			})
		},
		totalSubmit() {
			let requestArray = []
			//评价简历

			if (this.resumeText.trim().length > 0 || this.resumeScore > 0 || this.resumeResult > 0) {
				let data1 = {
					estimateText: this.resumeText,
					result: this.resumeResult,
					estimateScore: this.resumeScore,
					userId: this.userInfo.userId,
					jobseekerChannelId: this.jobseekerChannelId ? this.jobseekerChannelId : undefined,
					corpId: this.corpId,
					source: 1,
				}
				requestArray.push(addComment(data1))
			}
			//this.$toast({ type: 'warning', message: res.msg })

			//人工视频打分
			if (this.videoText.trim().length > 0 || this.videoScore > 0 || this.videoResult > 0) {
				let data2 = {
					estimateContent: this.videoText,
					interviewId: this.interviewId,
					estimateScore: this.videoScore,
					result: this.videoResult,
					estimateType: 2,
				}
				requestArray.push(markAddComprehensiveEstimate(data2))
			}

			//人工综合评价
			if (this.totalText.trim().length > 0 || this.totalScore > 0 || this.totalResult > 0) {
				let data3 = {
					estimateContent: this.totalText,
					interviewId: this.interviewId,
					estimateScore: this.totalScore,
					result: this.totalResult,
					estimateType: 1,
				}
				requestArray.push(markAddComprehensiveEstimate(data3))
			}

			if (requestArray && requestArray.length) {
				Promise.all(requestArray).then(result => {
					let flag = true
					for (let i = 0; i < result.length; i++) {
						if (result[i] && result[i].code !== 0) {
							this.$toast({ type: 'warning', message: result[i].msg })
							flag = false
						}
					}
					if (flag) {
						this.$toast({ type: 'info', message: '提交成功' })
					}

					this.resumeResult = -1
					this.resumeText = ''
					this.resumeScore = 0

					this.videoResult = -1
					this.videoText = ''
					this.videoScore = 0

					this.totalResult = -1
					this.totalText = ''
					this.totalScore = 0

					this.getAllEstimate()
				})
			} else {
				this.$toast({ type: 'info', message: '请至少填写一项' })
			}
		},
		closeAllVideo() {
			let videoArray = document.getElementsByTagName('video')
			if (videoArray && videoArray.length) {
				for (let i = 0; i < videoArray.length; i++) {
					videoArray[i].pause()
				}
			}
		},
		confirmDialog() {
			this.$store.commit('setShowManualEstimateDialog', false)
		},
		goBack() {
			console.log('122222222222')
			this.$router.go(-1)
			// if (this.estimateContent.trim().length > 0) {
			//   this.showConfirmDialog = true
			// } else {
			//   this.$store.commit('setShowManualEstimateDialog', false)
			// }
		},
		showEstimateRecord() {
			markShowComprehensiveEstimate({ interviewId: this.interviewId, estimateType: 1 }).then(res => {
				if (res && res.code === 0) {
					this.logList = res.data || []
				}
			})
		},

		onSubmit() {
			let data = {
				interviewId: this.interviewId,
				estimateScore: this.estimateScore,
				estimateContent: this.estimateContent,
				estimateType: 1,
			}
			markAddComprehensiveEstimate(data).then(res => {
				if (res && res.code === 0) {
					this.$toast({ type: 'info', message: '提交成功' })
					this.showEstimateRecord()
					this.estimateContent = ''
					this.estimateScore = 0
				} else {
					this.$message.error(res.msg)
				}
			})
		},
	},
}
</script>
<style lang="scss">
.manual-estimate-dialog {
	width: 100%;
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: #ffffff;
	z-index: 12;
	padding: 0.4rem 5vw;
	box-sizing: border-box;
	overflow-y: scroll;

	.text-description {
		font-size: 0.35rem;
		color: #000000;
	}

	.radio-wrapper {
		margin-top: 0.2rem;
		width: 100%;
		display: flex;
		justify-content: space-between;
		.radio-item {
			font-size: 0.35rem;
			width: 26vw;
			padding: 0.2rem 0;
			text-align: center;
			border: 1px solid #dcdfe6;
		}

		.active {
			background-color: #14b1ff;
			color: #ffffff;
		}
	}

	.rate-wrapper {
		margin-top: 0.2rem;
	}

	.van-field {
		margin-top: 0.2rem;
		background-color: #fafafa;
	}

	.btn-wrapper {
		display: flex;
		justify-content: flex-end;
		.confirm-btn {
			font-size: 0.35rem;
			padding: 0.2rem 0.5rem;
			background-color: #14b1ff;
			color: #ffffff;
			border-radius: 5px;
		}
	}

	.top-row {
		display: flex;
		.icon-wrapper {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			box-shadow: 0 0 1px #666666;
			line-height: 23px;
			text-align: center;
			i {
				font-size: 0.3rem;
			}
		}
		.text {
			margin-left: 0.2rem;
			font-weight: 600;
			font-size: 0.4rem;
		}
	}

	.estimate-row {
		box-sizing: border-box;
		width: 100%;
		padding-left: 0.5rem;
		margin-top: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.submit-btn {
			cursor: pointer;
			padding: 0.2rem;
			width: 2rem;
			color: #ffffff;
			background-color: #00aaff;
			text-align: center;
			font-size: 0.4rem;
		}
	}

	.desc {
		padding: 0.2rem 0;
		text-align: left;
	}

	.textarea-wrapper {
		width: 100%;
		box-sizing: border-box;
		/deep/ textarea {
			margin-top: 0.2rem;
			border: 1px solid #c8c9cc;
			border-radius: 4px;
			padding: 10px;
			transform: translateX(0.8);
			transform: translateX(10rpx);
			box-sizing: border-box;
		}
	}
	.suggerlog-wrapper {
		margin-top: 0.2rem;
		//border: 1px solid #cccccc;
		border-radius: 5px;
		padding: 12px 16px;
		.suggerlog {
			padding: 0.2rem 0;
			.line1 {
				display: flex;
				justify-content: space-between;

				.left {
					display: flex;
					align-items: center;
					.name {
						max-width: 1.7rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-weight: 600;
					}

					.dafen {
						margin-left: 0.1rem;
						font-weight: 600;
						//color: #cccccc;
					}
				}
			}
			.line2 {
				margin-top: 0.2rem;
				.pingjia {
					font-weight: 600;
				}
			}
		}
	}

	.estimate-log-wrapper {
		margin-top: 1rem;
		.estimate-log-item {
			margin-top: 0.5rem;
			.estimate-content-log {
				.top-title-row {
					display: flex;
					justify-content: space-between;
					.name {
						font-size: 0.35rem;
						color: #000000;
					}
					.date {
						font-size: 0.35rem;
						color: #666666;
					}
				}
				.content-wrapper {
					margin-top: 0.3rem;
					background-color: #fafafa;
					padding: 0.3rem;
					font-size: 0.35rem;
					color: #666666;
					.content-desc {
						font-size: 0.35rem;
						color: #000000;
					}
					.result-row {
						margin-top: 0.3rem;
						.text {
							margin-left: 0.3rem;
						}
					}

					.rate-row {
						margin-top: 0.3rem;
						display: flex;
						.van-rate {
							margin-left: 0.3rem;
						}
					}

					.estimate-content-row {
						margin-top: 0.3rem;
						.text {
							margin-left: 0.3rem;
						}
					}
				}
			}
		}
	}

	.dialog-wrapper {
		width: 80%;
		margin: 0 auto;
		padding: 1rem 0;
	}
}
</style>
